var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("q-table", {
          attrs: {
            data: _vm.grid.data,
            title: "일용직 근무자 목록",
            columns: _vm.grid.columns,
            filter: _vm.filter,
            selection: "multiple",
            "hide-pagination": true,
            grid: "",
            "hide-header": "",
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function (props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "q-pa-xs col-xs-3 col-sm-3 col-md-3 col-lg-3 grid-style-transition",
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticStyle: {
                            background:
                              "radial-gradient(circle, #f2f8fc 0%, #c5dcf0 100%)",
                          },
                        },
                        [
                          _c("q-card-section", [
                            _c("div", { staticClass: "text-h6" }, [
                              _vm._v(_vm._s(props.row.userName)),
                            ]),
                          ]),
                          _c("q-separator"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }