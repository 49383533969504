
<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-table
        :data="grid.data"
        title="일용직 근무자 목록"
        :columns="grid.columns"
        :filter="filter"
        selection="multiple"
        :hide-pagination="true"
        grid
        hide-header
      >
        <template v-slot:item="props">
          <div
            class="q-pa-xs col-xs-3 col-sm-3 col-md-3 col-lg-3 grid-style-transition"
          >
            <q-card style="background: radial-gradient(circle, #f2f8fc 0%, #c5dcf0 100%)">
              <q-card-section>
                <div class="text-h6">{{props.row.userName}}</div>
                <!-- <q-btn 
                  round unelevated 
                  size="10px"
                  color="amber" 
                  icon="mode"
                  @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                  >
                  <q-popup-proxy :ref="'proxy_' + props.rowIndex" :breakpoint="400">
                    <component
                      :is="col.component"
                      :outFlag="true"
                      :popupParam="props.row"
                      :data="data"
                      :rowIndex="props.rowIndex"
                      @callback="callback"
                    />
                  </q-popup-proxy>
                </q-btn> -->
              </q-card-section>
              <q-separator />
            </q-card>
          </div>
        </template>
      </q-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'commute',
  props: {
    popupParam: {
      type: Object,
      default() {
        return {
          tbmId: "",
        };
      },
    },
  },
  data() {
    return {
      filter: '',
      selected: [],
      grid: {
        columns:[
          {
            name: 'userName',
            userName: 'commuteDate',
            required: true,
            align: 'left',
            format: val => `${val}`,
            sortable: true
          },
          {
            name: 'commuteDate',
            field: 'commuteDate',
            label: '출근일',
            align: 'center',
            sortable: true,
          },
          {
            name: 'startTime',
            field: 'startTime',
            label: '출근시간',
            align: 'center',
            sortable: true,
          },
          {
            name: 'endTime',
            field: 'endTime',
            label: '퇴근시간',
            align: 'center',
            sortable: true,
          },
        ],
        data: [
        ]
      },
      data: {
        userId: '',
        userName: '',
        startTime: '',
        endTime: '',
        startFlag: 'N',
        endFlag: 'N',
        commuteDate: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      listUrl: '',
      isSave: false,
      mappingType: 'POST'
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      this.data.commuteDate = this.$comm.getToday();
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.cmt.userlist.url;
      this.getUserList();
    },
    getUserList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.data;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    datachange() {
      if (this.data.commuteDate) {
        this.getUserList();
        this.getList();
      } else {
        this.grid.data = [];
      }
    },
    callback(data, rowIndex) {
      let refName = 'proxy_' + rowIndex;
      this.$refs[refName].hide();
    },
  }
}
</script>

<style lang="sass">
.grid-style-transition
  transition: transform .28s, background-color .28s
</style>